/* Default Style */
:root {
  --color-primary: #fea46a;
  --color-primary-light: #fff3e9;
  --color-text: #fff;
  --color-yellow: #ecc315;
  --color-dark: #7c7b7b;
  --color-bg: #110d2e;
  --color-white: #fff;
  --color-black: #000;
  --font-primary: 'Open Sans', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-primary);
  background: var(--color-bg);
  color: var(--color-text);
  line-height: 1.5;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

p + p {
  margin-top: 15px;
}

input:focus,
textarea:focus,
button:focus {
  outline: none;
}

.logo {
  display: inline-block;
}

.logo .tagline {
  margin: -15px 0 0 37px;
  font-weight: 400;
  color: var(--color-text);
}

.main {
  position: relative;
  display: grid;
  grid-template-columns: 5fr 3fr;
  overflow: hidden;
}

.main > section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 5%;
  min-height: 100vh;
}

.formWrap {
  position: relative;
  background: var(--color-white);
  min-width: 450px;
}

.formWrapInner {
  position: relative;
  width: 100%;
  max-width: 450px;
  padding: 0 15px;
  margin: 0 auto;
  opacity: 0.2;
  transform: translate(5px, 20px);
  animation: fadeInUp 150ms ease 100ms forwards;
  z-index: 1;
}

.formWrapInner .box {
  position: relative;
  box-shadow: 0 15px 35px rgb(0 0 0 / 12%);
  color: var(--color-black);
  padding: 1.5rem;
  background: #fff;
  border-radius: 6px;
}

.box h4 {
  font-size: 1.25rem;
  margin: 0 0 15px;
}

@keyframes fadeInUp {
  0% {
    opacity: 0.2;
    transform: translate(5px, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.formWrap .logo {
  margin-bottom: 50px;
}

@keyframes btnIn {
  0% {
    box-shadow: 0 0 0;
  }
  100% {
    box-shadow: -5px 3px 5px rgb(0 0 0 / 5%);
  }
}

.buttons {
  display: flex;
  grid-gap: 20px;
  justify-content: center;
  margin: 2.5rem auto;
}

.buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  text-decoration: none;
  color: var(--color-black);
  font-family: var(--font-primary);
  font-size: 0.75rem;
  border: 2px solid transparent;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.buttons a.active {
  border-color: var(--color-black);
}

.inputWrap + .inputWrap {
  margin-top: 20px;
}

.formGroup {
  position: relative;
  padding: 10px 15px;
  padding-top: 5px;
  background: #fff;
  box-shadow: 16px 22px 35px rgb(0 0 0 / 12%);
  border-radius: 10px;
  border: 2px solid transparent;
  overflow: hidden;
  transition: all 0.5s;
}

.formGroup:focus-within {
  background: var(--color-primary-light);
  box-shadow: 0 0 0;
  border-color: var(--color-primary);
}

.inputError {
  font-size: 13px;
  color: #ff6f00;
  letter-spacing: 1px;
}

.formGroup label {
  color: var(--color-black);
  transform: translateY(-50%);
  text-transform: uppercase;
  font-size: 0.75rem;
}

label .icon {
  display: block;
  position: absolute;
  margin-top: 10px;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.formGroup input {
  width: 100%;
  font-family: var(--font-primary);
  font-size: 1rem;
  color: var(--color-dark);
  border: none;
  background: transparent;
  padding: 8px 0;
  padding-left: 35px;
}

form button[type='submit'] {
  display: block;
  width: 100%;
  padding: 20px 25px;
  border: none;
  border-radius: 6px;
  background: var(--color-primary);
  color: var(--color-white);
  cursor: pointer;
  font-family: var(--font-primary);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  transition: all 0.4s;
}

form button[type='submit']:not(:disabled):hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

form button:disabled {
  background: var(--color-dark);
  color: var(--color-white);
  cursor: not-allowed;
}

.accountMsg {
  color: var(--color-dark);
  margin-top: 15px;
}

.formStatus {
  font-size: 13px;
  letter-spacing: 1px;
  padding: 10px;
  border-radius: 4px;
  color: var(--color-text);
  margin-bottom: 15px;
}

.formStatus.success {
  background: #0e9c21;
}

.formStatus.error {
  background: #ff6f00;
}

/* Content STYLE */
.content {
  position: relative;
  text-align: right;
  z-index: 2;
}

.content h3 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.content h3 span {
  color: var(--color-yellow);
}

.content h4 {
  font-weight: 600;
  font-size: 2rem;
  color: var(--color-primary);
  margin: 15px 0 0;
}

/* Ingredients */
.dime {
  position: absolute;
  top: 2%;
  z-index: 0;
}

.dime2 {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 70%;
}

.dime2 img {
  width: 100%;
}

.guilloche {
  position: absolute;
  top: 0;
  width: 50%;
}

.guilloche.left {
  left: 0;
}

@media (max-width: 1200px) {
  .formWrap {
    min-width: 350px;
  }

  .content h1 {
    font-size: 1.75rem;
  }

  .content h2 {
    font-size: 1.15rem;
  }
}

@media (max-width: 992px) {
  .content h3 {
    font-size: 2.5rem;
  }
  .content h4 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .formWrapInner {
    padding: 0;
  }

  .dime {
    top: 5px;
  }

  .dime img {
    max-width: 50px;
  }

  .contentWrap {
    display: none !important;
  }

  .buttons {
    display: flex;
  }

  .main {
    display: block;
  }
}
